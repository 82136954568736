@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Pretendard";
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@layer components {
  .postlist-title {
    @apply text-[22px] font-semibold text-[#212121];
  }

  .postlist-colortitle {
    @apply bg-opacity-10 bg-[#2F80ED] text-[#2F80ED] py-[4px] px-[12px] text-[12px] rounded;
  }

  .postitem-title {
    @apply font-semibold text-[#212121] text-[18px];
  }

  .postitem-title2 {
    @apply text-[#424242] text-[16px] font-medium;
  }
}

@font-face {
  font-family: "Pretendard";
  font-weight: 100;
  src: url("assets/fonts/Pretendard-Thin.woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 200;
  src: url("assets/fonts/Pretendard-ExtraLight.woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 300;
  src: url("assets/fonts/Pretendard-Light.woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 400;
  src: url("assets/fonts/Pretendard-Regular.woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 500;
  src: url("assets/fonts/Pretendard-Medium.woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 600;
  src: url("assets/fonts/Pretendard-SemiBold.woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 700;
  src: url("assets/fonts/Pretendard-Bold.woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 800;
  src: url("assets/fonts/Pretendard-ExtraBold.woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 900;
  src: url("assets/fonts/Pretendard-Black.woff");
}
